<template>
    <div class="row border-bottom white-bg">
        <nav class="navbar navbar-static-top" role="navigation">
          <div class="navbar-header">
            <button
              aria-controls="navbar"
              aria-expanded="false"
              data-target="#navbar"
              data-toggle="collapse"
              class="navbar-toggle collapsed"
              type="button"
            >
              <i class="fa fa-reorder"></i>
            </button>
            <a href="#" class="navbar-brand">INKA</a>
          </div>
          <div class="navbar-collapse collapse" id="navbar">
            <ul class="nav navbar-nav">
              <li>
                <router-link to="/dashboard" aria-expanded="false" role="button"><i class="fa fa-th-large"></i> Dashboard</router-link>               
              </li>
              <li>
                <router-link to="/lista_servicios" aria-expanded="false" role="button"><i class="fa fa-database"></i> Lista servicios</router-link>               
              </li>
              <li>               
                <router-link to="/nodos" aria-expanded="false" role="button"><i class="fa fa-th-list"></i> Nodos</router-link>               
              </li>
              <li>
                <router-link to="/clientes" aria-expanded="false" role="button"><i class="fa fa-users"></i> Clientes</router-link>               
              </li>
              <li>                
                <router-link to="/logs" aria-expanded="false" role="button"><i class="fa fa-file-text-o"></i> Logs</router-link>               
              </li>
              <li>
                <router-link to="/usuarios" aria-expanded="false" role="button"><i class="fa fa-th-large"></i> Usuarios</router-link>               
              </li>
            </ul>
            <ul class="nav navbar-top-links navbar-right">
              <li v-if="nombreUsuario">
                <span class="m-r-sm text-muted welcome-message" ><i class="fa fa-user"></i> {{nombreUsuario}} </span>
              </li>
              <li>
               <router-link to="/" aria-expanded="false" role="button"><i class="fa fa-sign-out"></i> Salir</router-link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
</template>
<script src='@/views/Menu.js?t=1654097249'></script>