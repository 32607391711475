import InkaDataService from '@/InkaDataService'
export default {
    namespaced: true,
    state: {
        nodos: [],
    },
    getters: {
    },
    mutations: {
        getNodos(state, dataNodos) {
            state.nodos = dataNodos;
        },
    },
    actions: {
        getInfoNodo: function ({ commit }) {
            let data = InkaDataService.getDatosDashboard();
            data.then((value) => {
                commit('getNodos', value);
            });
        },
    }
}