import InkaDataService from '@/InkaDataService'
export default {
    namespaced: true,
    state: {
        infoUser: [],
        usuario:null,
        contrasena:null
    },
    getters: {
    },
    mutations: {
        updateUsuario(state, user) {
            state.usuario = user
        },
        updateContrasena(state, pass) {
            state.contrasena = pass
        },
        getInfoUser(state,info){
            state.infoUser = info
        }
    },
    actions: {
        validaLogin: async function ({ commit },datos) {
            let data = await InkaDataService.validaDatosLogin(datos);
            if(data){
                commit("getInfoUser",data)
                return true
            }else{
                return false
            }
        },
    }
}