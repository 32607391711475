import InkaDataService from '@/InkaDataService'
export default {
    namespaced: true,
    state: {
        log: [],
    },
    getters: {
    },
    mutations: {
        getLog(state, dataLog) {
            state.log = dataLog;
        },
    },
    actions: {
        getInfoLog: async function ({ commit }) {
            let data = await InkaDataService.getDatosLog();
            if(data){
                console.log(data);
                commit('getLog',data)  
            }else{
                return false
            }           
        },
    }
}