import router from '@/router';
import { mapState, mapActions } from 'vuex';
export default {
  name: 'Menu',
  computed: {
    ...mapState('LoginStore', ['infoUser']),
    nombreUsuario() {
      if (this.infoUser[0]) {
        return this.infoUser[0].nombre;
      }
    }
  },
  created() {
    if (!this.infoUser[0]) {
      router.push("/");
    } else {
      window.addEventListener("beforeunload", (e) => {
        e.preventDefault();
        e.returnValue = '';
      })
    }
  },
  methods: {
    logOut(){
      console.log("out");
    }
  }
}