import InkaDataService from '@/InkaDataService'
export default {
    namespaced: true,
    state: {
        listaNodos: [],
        nombre: null,
        ip: null,
        dominio: null,
        nodo_id: null
    },
    getters: {
    },
    mutations: {
        getListaNodos(state, dataNodos) {
            state.listaNodos = dataNodos;
        },
        updateNombre(state, nombreNodo) {
            state.nombre = nombreNodo
        },
        updateIp(state, ip_nodo) {
            state.ip = ip_nodo
        },
        updateDominio(state, dominio_nodo) {
            state.dominio = dominio_nodo
        },
        updateNodoId(state, id_nodo) {
            state.nodo_id = id_nodo
        },
        getNodoDetalle(state, nodo_detail) {
            state.nodoDetalle = nodo_detail
        }
    },
    actions: {
        getInfoNodosMantenedor: function ({ commit }) {
            let data = InkaDataService.getAllNodos();
            data.then((value) => {
                console.log(value);
                commit('getListaNodos', value);
            });
        },
        nuevoNodo: async function ({ commit }, aNodos) {
            let data = await InkaDataService.addNewNodo(aNodos);
            if (data) {
                commit('getListaNodos', data)
                return true
            } else {
                return false
            }
        },
        getDatosNodo: async function ({ commit }, nodo_id) {
            let data = await InkaDataService.getDatosNodoById(nodo_id);
            if (data) {
                commit('updateNodoId', data[0].nodo_id);
                commit('updateNombre', data[0].nombre);
                commit('updateIp', data[0].host);
                commit('updateDominio', data[0].dominio);
            } else {
                return false;
            }
        },
        setNodo: async function ({ commit }, datosNodo) {
            let data = await InkaDataService.setDatosNodo(datosNodo);
            if (data) {
                commit('getListaNodos', data)
                return true
            } else {
                return false;
            }
        },
    }
}