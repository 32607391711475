import axios from "axios";
var InkaDataService = {
    //RUTAS DESARROLLO//
    /*urlDashboard: "http://localhost:81/inka_dev/src/backend/dashboardAPI/ajax_dashboard.php",
    urlServicios: "http://localhost:81/inka_dev/src/backend/serviciosAPI/ajax_servicios.php",
    urlNodos: "http://localhost:81/inka_dev/src/backend/nodosAPI/ajax_nodos.php",
    urlLogin: "http://localhost:81/inka_dev/src/backend/loginAPI/ajax_login.php",
    urlLog: "http://localhost:81/inka_dev/src/backend/logAPI/ajax_log.php",
    urlCliente: "http://localhost:81/inka_dev/src/backend/clienteAPI/ajax_cliente.php",*/
    //RUTAS PRODUCCION//
    urlDashboard: "backend/dashboardAPI/ajax_dashboard.php",
    urlServicios:"backend/serviciosAPI/ajax_servicios.php",
    urlNodos: "backend/nodosAPI/ajax_nodos.php",
    urlLogin: "backend/loginAPI/ajax_login.php",
    urlLog: "backend/logAPI/ajax_log.php",
    urlcliente: "backend/clienteAPI/ajax_cliente.php",

    getDatosDashboard: async function () {
        let rs = await axios
            .post(this.urlDashboard, { action: "getNodos" })
            .then((res) => {
                const result = this.handleSuccess(res.data);
                return result;
            })
            .catch((e) => console.log(e));
        return rs;
    },
    handleSuccess: function (response) {
        if (typeof response.data == "string") {
            if (response.headers("content-type").indexOf("text/html") > -1) {
                return response.data;
            } else {
                return JSON.parse(response);
            }
        } else {
            if (response.status == "ERROR") {
                return false;
            } else {
                return response.data;
            }
        }
    },
    getListaServicios: async function () {
        let rs = await axios
            .post(this.urlServicios, { action: "getServicios" })
            .then((res) => {
                const result = this.handleSuccess(res.data);
                return result;
            })
            .catch((e) => console.log(e));
        return rs;
    },
    getDetailServicio: async function (app_id) {
        let rs = await axios
            .post(this.urlServicios, { action: "getDatosServicio", app_id: app_id })
            .then((res) => {
                const result = this.handleSuccess(res.data);
                return result;
            })
            .catch((e) => console.log(e));
        return rs;
    },
    setDatosServicio: async function (datos) {
        let rs = await axios
            .post(this.urlServicios, { action: "setDatosServicio", datos: datos })
            .then((res) => {
                const result = this.handleSuccess(res.data);
                return result;
            })
            .catch((e) => console.log(e));
        return rs;
    },
    getAllNodos: async function () {
        let rs = await axios
            .post(this.urlNodos, { action: "getAllNodos" })
            .then((res) => {
                const result = this.handleSuccess(res.data);
                return result;
            })
            .catch((e) => console.log(e));
        return rs;
    },
    validaDatosLogin: async function (datos) {
        let rs = await axios
            .post(this.urlLogin, { action: "validaLogin", datos: datos })
            .then((res) => {
                const result = this.handleSuccess(res.data);
                return result;
            })
            .catch((e) => console.log(e));
        return rs;
    },
    setEstado: async function (app_id) {
        let rs = await axios
            .post(this.urlServicios, { action: "setEstadoServicio", app_id: app_id })
            .then((res) => {
                const result = this.handleSuccess(res.data);
                return result;
            })
            .catch((e) => console.log(e));
        return rs;
    },
    getDatosLog: async function () {
        let rs = await axios
            .post(this.urlLog, { action: "getDataLog" })
            .then((res) => {
                const result = this.handleSuccess(res.data);
                return result;
            })
            .catch((e) => console.log(e));
        return rs;
    },
    listarAppKey: async function (keyApp) {
        let rs = await axios
            .post(this.urlServicios, { action: "getListaAppKey", keyApp: keyApp })
            .then((res) => {
                const result = this.handleSuccess(res.data);
                return result;
            })
            .catch((e) => console.log(e));
        return rs;
    },
    addNewNodo: async function (aDatos) {
        let rs = await axios
            .post(this.urlNodos, { action: "addNodo", aDatos: aDatos })
            .then((res) => {
                const result = this.handleSuccess(res.data);
                return result;
            })
            .catch((e) => console.log(e));
        return rs;
    },
    getDatosNodoById: async function (nodo_id) {
        let rs = await axios
            .post(this.urlNodos, { action: "getDatosNodo", nodo_id: nodo_id })
            .then((res) => {
                const result = this.handleSuccess(res.data);
                return result;
            })
            .catch((e) => console.log(e));
        return rs;
    },
    setDatosNodo: async function (datosNodo) {
        let rs = await axios
            .post(this.urlNodos, { action: "setNodo", datosNodo: datosNodo })
            .then((res) => {
                const result = this.handleSuccess(res.data);
                return result;
            })
            .catch((e) => console.log(e));
        return rs;
    },
    addNewService: async function (datos) {
        let rs = await axios
            .post(this.urlServicios, { action: "newService", datos: datos })
            .then((res) => {
                const result = this.handleSuccess(res.data);
                return result;
            })
            .catch((e) => console.log(e));
        return rs;
    },
    getClienteList: async function () {
        let rs = await axios
            .post(this.urlCliente, { action: "getClientes"})
            .then((res) => {
                const result = this.handleSuccess(res.data);
                return result;
            })
            .catch((e) => console.log(e));
        return rs;
    },
    deleteServicio:  async function (app_id) {
        let rs = await axios
            .post(this.urlServicios, { action: "deleteService",app_id:app_id})
            .then((res) => {
                const result = this.handleSuccess(res.data);
                return result;
            })
            .catch((e) => console.log(e));
        return rs;
    },
}

export default InkaDataService;