<template>
  <div id="wrapper">
    <div id="page-wrapper" class="gray-bg">
      <Menu v-if="currentRouteName != '/'"></Menu>  
      <div class="wrapper wrapper-content">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import Menu from "@/views/Menu.vue";
export default {
  components: {
    Menu,
  }, 
  computed: {
    currentRouteName() {       
      return this.$route.path;
    },
  },
};
</script>

