import InkaDataService from '@/InkaDataService'

export default {
    namespaced: true,
    state: {
        servicios: [],
        app_id: null,
        app_name: null,
        app_data1: null,
        app_data2: null,
        app_data3: null,
        app_data4: null,
        app_data5: null,
        app_data6: null,
        app_data7: null,
        app_host: null,
        app_port: null,
        observacion: null,
        nodo: null,
        cliente_id : null,
        setAppId: null,
        appKeyList: null,
        nodoLista: null
    },
    getters: {
    },
    mutations: {
        getServicios(state, dataServicios) {
            state.servicios = dataServicios;
        },
        getListaNodo(state, nodoList) {
            state.nodoLista = nodoList
        },
        updateAppName(state, app_name) {
            state.app_name = app_name
        },
        updateAppData1(state, app_data1) {
            state.app_data1 = app_data1
        },
        updateAppData2(state, app_data2) {
            state.app_data2 = app_data2
        },
        updateAppData3(state, app_data3) {
            state.app_data3 = app_data3
        },
        updateAppData4(state, app_data4) {
            state.app_data4 = app_data4
        },
        updateAppData5(state, app_data5) {
            state.app_data5 = app_data5
        },
        updateAppData6(state, app_data6) {
            state.app_data6 = app_data6
        },
        updateAppData7(state, app_data7) {
            state.app_data7 = app_data7
        },
        updateAppHost(state, app_host) {
            state.app_host = app_host
        },
        updateAppPort(state, app_port) {
            state.app_port = app_port
        },
        updateNodo(state, nodo) {
            state.nodo = nodo
        },
        updateAppId(state, app_id) {
            state.app_id = app_id
        },
        updateSetAppId(state, id_app) {
            state.setAppId = id_app
        },
        updateObservacion(state, observacion) {
            state.observacion = observacion
        },
        updateClienteId(state, id_cliente) {
            state.cliente_id = id_cliente
        },
        getAppKey(state, appkey) {
            state.appKeyList = appkey
        }
    },
    actions: {
        getInfoServicios: async  function ({ commit }) {
            let data = await InkaDataService.getListaServicios();            
            if(data){
                console.log(data);
                commit('getServicios', data);
            }
        },
        getDetalleServicio: function ({ commit }, app_id) {
            let data = InkaDataService.getDetailServicio(app_id);
            data.then((value) => {
                console.log(value);
                commit('updateAppId', value[0].app_id);
                commit('updateAppName', value[0].app_name);
                commit('updateAppData1', value[0].app_data1);
                commit('updateAppData2', value[0].app_data2);
                commit('updateAppData3', value[0].app_data3);
                commit('updateAppData4', value[0].app_data4);
                commit('updateAppData5', value[0].app_data5);
                commit('updateAppData6', value[0].app_data6);
                commit('updateAppData7', value[0].app_data7);
                commit('updateAppHost', value[0].app_host);
                commit('updateAppPort', value[0].app_port);
                commit('updateNodo', value[0].nodo);
                commit('getListaNodo', value[0].comboNodo);
                commit('updateObservacion', value[0].observacion);
            });
        },
        setInfoServicio: async function ({ commit }, aServicio) {
            let data = await InkaDataService.setDatosServicio(aServicio);
            if (data) {
                commit('getServicios', data)
                return true
            } else {
                return false
            }
        },
        setEstadoServicio: async function ({ commit }, app_id) {
            let data = await InkaDataService.setEstado(app_id);
            if (data) {
                commit('getServicios', data)
                return true
            } else {
                return false
            }
        },
        listaAppKey: async function ({ commit }, keyApp) {
            let data = await InkaDataService.listarAppKey(keyApp);
            if (data) {
                commit('getAppKey', data)
            } else {
                return false
            }
        },
        newService: async function ({ commit }, aServicio) {
            let data = await InkaDataService.addNewService(aServicio);
            if (data) {
                commit('getServicios', data)
                return true
            } else {
                return false
            }
        },
        deleteService: async function ({ commit }, app_id) {
            let data = await InkaDataService.deleteServicio(app_id);
            if (data) {
                commit('getServicios', data)
                return true
            } else {
                return false
            }
        },
    }
}