import InkaDataService from '@/InkaDataService'
export default {
    namespaced: true,
    state: {
        clientes: [],
    },
    getters: {       
    },
    mutations: {
        getClientes(state, dataClientes) {
            state.clientes = dataClientes;
        },
    },
    actions: {
        getListaClientes: function ({ commit }) {
            let data = InkaDataService.getClienteList();
            data.then((value) => {
                commit('getClientes', value);
            });
        },
    }
}