import { createStore } from 'vuex'
import DashboardStore from '@/store/DashboardStore'
import ServiciosStore from '@/store/ServiciosStore'
import NodosStore from '@/store/NodosStore'
import LoginStore from '@/store/LoginStore'
import LogStore from '@/store/LogsStore'
import ClienteStore from '@/store/ClienteStore'
export default createStore({
  state: {   
   
  },
  getters: {
  },
  mutations: {   
   
  },
  actions: {
   
  
  },
  modules: {
    DashboardStore,
    ServiciosStore,
    NodosStore,
    LoginStore,
    LogStore,
    ClienteStore
  }
})
