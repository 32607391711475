import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import("../views/Login.vue"),
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import("../views/Dashboard.vue"),
  },
  {
    path: '/lista_servicios',
    name: 'lista_servicios',
    component: () => import("../views/Servicios.vue"),
  },
  {
    path: '/nodos',
    name: 'nodos',
    component: () => import("../views/Nodos.vue"),
  },  
  {
    path: '/clientes',
    name: 'clientes',
    component: () => import("../views/Clientes.vue"),
  },
  {
    path: '/logs/:key_app?',
    name: 'logs',
    component: () => import("../views/Logs.vue"),
  },
  {
    path: '/usuarios',
    name: 'usuarios',
    component: () => import("../views/Usuarios.vue"),
  },
  /*{
    path: '/dashboard_detalle/:nodo_id',
    name: 'dashboard_detalle',
    component: () => import("../views/DashboardDetalleView.vue"),
  }*/
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
